// Here you can add other styles

.btn-primary {
  background: #f0584a !important;
  border: 1px solid #f0584a !important;
}

.c-primary {
  color: #f0584a !important;
}

.c-success {
  color: #2eb85c !important;
}

.button-icon {
  border: 0;
  outline: none;
  background: transparent;
  display: inline;
}

.counter {
  text-align: right;
  font-size: 20pt;
  span {
    position: relative;
    padding: 3px 10px;
    background: #f0584a;
    color: white;
    margin: 0 5px;
    border-radius: 5px;
  }
}

.webCam {
  text-align: center;
}

.c-grace {
  color: rgba(0, 0, 21, 0.5)
}

.radius-50 {
  border-radius: 50%;
}

.pointer-link {
  @extend .c-primary;
  cursor: pointer;
  text-decoration: underline;
}
